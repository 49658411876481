export const COLORS = {
  cardMessageBlue: '#6379f1',
  cardMessageGray: '#e0e0e0',
  reasonN1:'#4F88C4',
  reasonN2:'#EB7D3C',
  reasonN3:'#FF6868',
  // cardMessageGreen: '#ddf7b5ff',
  cardMessageGreen: '#eaffc8',
  cardMessageYellow: '#fff4c8',
  cardMessageWhite: '#ffffff',
  cardMessagePink: '#ebccfb',
  whiteText: '#fff',
  grayText: '#666666',
  blueText: '#3d85c6',
  blackText: '#424242',
  lightGray: '#f5f5f5',
  greenText: '#80c343',
  darkGreenText: '#508738',
  btnPrimaryColor: '#007bff',
  btnSecondaryColor: '#89898b',
  btnSuccessColor: '#80c343',
  btnDangerColor: '#dc3545',
  btnWarningColor: '#ffc107',
  btnInfoColor: '#17a2b8',
  btnLightColor: '#e0e0e0',
  btnDarkColor: '#000000',
  btnLinkColor: '#007bff',
  divider: '#D3D3D3',
  borderGray: '#ddd',
  backgroundGray: '#f4f6f8',
  lightGreen: '#80c34330',
  WarningColorSLA: '#f60',
  WarningV2: '#ffcc00',
  WarningV3: '#ff9900',
  gray: '#eeeeee',
  orange: '#ff6600',
  meduimGray: '#999999',
  presaleOrange: '#EB9B39',
  bgModal: '#f3f3f3',
  timeline: {
    passedBackgroundColor: '#6fa8dcff',
    passedBorderColor: '#6fa8dcff',
    defaultBackgroundColor: '#e7e6e6ff',
    defaultBorderColor: '#cccccc',
    labelActiveColor: '#ed7d31',
    labelDefaultColor: '#666666',
    actualBackgroundColor: '#FFECCC',
    actualBorderColor: '#FF9900',
    startedDatetime: '#80c343',
    errorBackgroundColor: '#ff0000',
    labelLastColor: '#70AD47'
  },
  dashboardTeamDataGray: '#C7C7C7',
  slaExpiredTitle: '#D83B4D',
  dashboardNoDrilldownItems: '#5F676C'
};
