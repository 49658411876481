/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import API from '../../../services/api';
import formHelper from '../../../utils/formHelper';
import reasonList from '../../../utils/reasonList';

import { inputStyles, theme } from '../../../components/inputStyle';

import { COLORS } from '../../../styles/settings/colors.config';

const NewTicket = ({ handleClose, refreshTableContent, marketplace }) => {
  const { t } = useTranslation();
  const classes = inputStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [mascaraTelefone, setMascaraTelefone] = useState('(99)99999-9999');
  const [maskCpfCnpj, setMaskCpfCnpj] = React.useState('999.999.999-999');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [reasonDetail, setReasonDetail] = useState([]);

  const convertFileToBase64 = async (file) => {
    if (!file) return;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onDrop = async (acceptedFiles) => {
    const newFilesState = [...filesToUpload];
    if (newFilesState.length + acceptedFiles.length > 4) {
      enqueueSnackbar('Não é possível incluir mais que 4 imagens', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }
    const hasFileBiggerThan2mb = acceptedFiles.find(
      (file) => file.size > 2000000
    );
    if (hasFileBiggerThan2mb) {
      enqueueSnackbar(
        `A imagem ${hasFileBiggerThan2mb.path} não pode ser enviada pois ela tem mais de 2MB`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      );
      return;
    }
    // eslint-disable-next-line
    for (const element of acceptedFiles) {
      // eslint-disable-next-line
      const base64 = await convertFileToBase64(element);
      newFilesState.push({
        path: element.path,
        binary: base64.split('base64,')[1]
      });
    }
    setFilesToUpload(newFilesState);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 4
  });

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={index}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      cpf: {
        value: '',
        required: true,
        masked: true,
        cnpjOnly: marketplace === 'ACER STORE B2B'
      },
      email: {
        required: true
      },
      phone: {
        required: true,
        masked: true,
        leaveOnlyNumbersWhenSubmit: true
      },
      reasonNew: {
        required: true
      },
      detail: {
        required: true
      },
      orderNumber: {
        required: false
      },
      subject: {
        required: true
      },
      description: {
        required: true
      },
      name: {
        required: true
      }
    })
  );

  const handleCpfCnpjMask = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };

  const handleCnpjMask = () => {
    setMaskCpfCnpj('99.999.999/9999-99');
  };

  const submitForm = () => {
    if (formData?.cpf?.value) {
      formData.cpf.value = formData.cpf.value.replace(/\D/g, '');
    }
    if (!formHelper.validaForm(formData, setFormdata)) {
      enqueueSnackbar(t('i18n.newticket.CHECK_IF_FIELDS_ARE_FILLED'), {
        variant: 'error'
      });
      return;
    }

    const postData = formHelper.formData2PostData(formData);
    postData.attachments = filesToUpload.map((file) => {
      return {
        name: file.path,
        content: file.binary
      };
    });

    postData.reasonNew = {
      group: formData.reasonNew.value.split(',')[0],
      name: formData.reasonNew.value.split(',')[1],
      desc: formData.detail.value
    };

    postData.marketplace = marketplace;

    API.post(
      `/auth/ms-ticket/v1/tickets/customerguide/new-attendant-ticket`,
      postData
    )
      .then(() => {
        handleClose();
        refreshTableContent();
      })
      .catch(() => {});
  };

  useEffect(() => {
    const [group, name] = formData.reasonNew.value.split(',');
    const foundDetail = reasonList().find(
      (el) => el.name === name && el.group === group
    );
    let details = [];
    if (foundDetail) {
      details = foundDetail.detail.map((item) => {
        return item.name;
      });
    }
    setReasonDetail(details);
  }, [formData.reasonNew.value]);

  const SUBJECTS = [
    'Acer Club',
    'Cadastro',
    'Cancelamento',
    'Entrega/Rastreio',
    'Garantia',
    'Nota Fiscal',
    'Pedido',
    'Suporte Técnico',
    'Troca'
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box mb={3} width="100%">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box mb={6} style={{ marginBottom: '0px' }}>
                <TextField
                  onChange={(htmlElemtEvent) => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'name',
                      htmlElemtEvent.target.value
                    );
                  }}
                  className={classes.inputSmall}
                  size="small"
                  label="Nome"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={6} style={{ marginBottom: '0px' }}>
                <InputMask
                  onPaste={(e) =>
                    marketplace === 'ACER STORE B2C'
                      ? handleCpfCnpjMask(e.clipboardData.getData('Text'))
                      : handleCnpjMask()
                  }
                  mask={maskCpfCnpj}
                  maskChar=""
                  size="small"
                  value={formData.cpf.value}
                  onChange={(htmlElemtEvent) => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'cpf',
                      htmlElemtEvent.target.value
                    );
                    if (marketplace === 'ACER STORE B2C') {
                      if (htmlElemtEvent.target.value.length > 14) {
                        setMaskCpfCnpj('99.999.999/9999-99');
                      } else {
                        setMaskCpfCnpj('999.999.999-999');
                      }
                    } else {
                      setMaskCpfCnpj('99.999.999/9999-99');
                    }
                  }}
                >
                  {() => (
                    <TextField
                      style={{ marginBottom: '12px' }}
                      size="small"
                      className={classes.inputSmall}
                      error={formData.cpf.error}
                      label={
                        marketplace === 'ACER STORE B2C'
                          ? t('i18n.newticket.CPF')
                          : t('i18n.newticket.CNPJ')
                      }
                      variant="outlined"
                      helperText={
                        marketplace === 'ACER STORE B2C'
                          ? t('i18n.newticket.CPF_HELPER_TEXT')
                          : t('i18n.newticket.CNPJ_HELPER_TEXT')
                      }
                      fullWidth
                    />
                  )}
                </InputMask>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={6} style={{ marginBottom: '16px' }}>
                <TextField
                  onChange={(htmlElemtEvent) => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'email',
                      htmlElemtEvent.target.value
                    );
                  }}
                  className={classes.inputSmall}
                  size="small"
                  error={formData.email.error}
                  label={t('i18n.newticket.EMAIL')}
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={6} style={{ marginBottom: '16px' }}>
                <InputMask
                  mask={mascaraTelefone}
                  onChange={(htmlElemtEvent) => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'phone',
                      htmlElemtEvent.target.value
                    );
                  }}
                  onBlur={(htmlElemtEvent) => {
                    formHelper.telefoneFlex(
                      'phone',
                      formData,
                      setFormdata,
                      setMascaraTelefone,
                      htmlElemtEvent
                    );
                  }}
                  onFocus={() => {
                    setMascaraTelefone('(99)99999-9999');
                  }}
                >
                  {() => (
                    <TextField
                      className={classes.inputSmall}
                      size="small"
                      error={formData.phone.error}
                      label={t('i18n.newticket.PHONE')}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                error={formData.reasonNew.error}
                fullWidth
                variant="outlined"
                size="small"
              >
                <InputLabel>
                  {formData.reasonNew.value ? t('i18n.newticket.REASON') : ''}
                </InputLabel>
                <Select
                  native
                  className="small-input-native"
                  size="small"
                  value={formData.reasonNew.value}
                  onChange={(htmlElemtEvent) => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'reasonNew',
                      htmlElemtEvent.target.value
                    );
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'detail',
                      ''
                    );
                  }}
                  label={t('i18n.newticket.REASON')}
                >
                  <option disabled value="">
                    {t('i18n.simplephrases.SELECT_REASON')}
                  </option>
                  <optgroup label="N1">
                    {reasonList()
                      .filter((el) => el.group === 'N1')
                      .map((el) => {
                        return (
                          <option
                            key={`${el.group}${el.name}`}
                            value={[el.group, el.name]}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                  </optgroup>
                  <optgroup label="N2">
                    {reasonList()
                      .filter((el) => el.group === 'N2')
                      .map((el) => {
                        return (
                          <option
                            key={`${el.group}${el.name}`}
                            value={[el.group, el.name]}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                  </optgroup>
                  <optgroup label="N3">
                    {reasonList()
                      .filter((el) => el.group === 'N3')
                      .map((el) => {
                        return (
                          <option
                            key={`${el.group}${el.name}`}
                            value={[el.group, el.name]}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {formData.reasonNew.value.split(',')[1] !== 'Outros' && (
                <FormControl
                  error={formData.detail.error}
                  fullWidth
                  variant="outlined"
                  size="small"
                >
                  <InputLabel>
                    {formData.detail.value
                      ? t('i18n.newticket.REASON_DETAIL')
                      : ''}
                  </InputLabel>
                  <Select
                    native
                    className="small-input-native"
                    size="small"
                    value={formData.detail.value}
                    onChange={(htmlElemtEvent) => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'detail',
                        htmlElemtEvent.target.value
                      );
                    }}
                    label={t('i18n.newticket.REASON_DETAIL')}
                  >
                    <option disabled value="">
                      {t('i18n.simplephrases.SELECT_REASON_DETAIL')}
                    </option>
                    {reasonDetail.map((el, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={index} value={el}>
                          {el}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {formData.reasonNew.value.split(',')[1] === 'Outros' && (
                <Box>
                  <TextField
                    onChange={(htmlElemtEvent) => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'detail',
                        htmlElemtEvent.target.value
                      );
                    }}
                    className={classes.inputSmall}
                    size="small"
                    error={formData.detail.error}
                    label={t('i18n.newticket.REASON_DETAIL')}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    helperText={`${formData.detail.value.length}/30`}
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControl
                error={formData.subject.error}
                fullWidth
                variant="outlined"
                size="small"
              >
                <InputLabel>
                  {formData.subject.value ? 'Assunto' : ''}
                </InputLabel>
                <Select
                  native
                  className="small-input-native"
                  size="small"
                  value={formData.subject.value}
                  onChange={(htmlElemtEvent) => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'subject',
                      htmlElemtEvent.target.value
                    );
                  }}
                  label="Assunto"
                >
                  <option disabled value="">
                    Selecione o assunto
                  </option>
                  {SUBJECTS.map((el, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <option key={index} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(htmlElemtEvent) => {
                  formHelper.setFormvalue(
                    formData,
                    setFormdata,
                    'orderNumber',
                    htmlElemtEvent.target.value
                  );
                }}
                error={formData.orderNumber.error}
                label={t('i18n.newticket.ORDER_NUMBER')}
                defaultValue=""
                variant="outlined"
                fullWidth
                className={classes.inputSmall}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(htmlElemtEvent) => {
                  formHelper.setFormvalue(
                    formData,
                    setFormdata,
                    'description',
                    htmlElemtEvent.target.value
                  );
                }}
                error={formData.description.error}
                fullWidth
                label={t('i18n.newticket.DESCRIPTION')}
                multiline
                rows={3}
                variant="outlined"
                className={classes.inputSmall}
                size="small"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <Box mt={3}>
        <Divider />
        <CardContent style={{ background: COLORS.gray }}>
          <Typography variant="overline">
            {t('i18n.newticket.SEND_FILES')}
          </Typography>
          <Paper
            {...getRootProps({ className: 'dropzone' })}
            style={{
              padding: '20px',
              border: 'dashed 2px #ccc',
              textAlign: 'center'
            }}
            variant="outlined"
          >
            <input {...getInputProps()} />
            <Typography>
              {t('i18n.newticket.DROP_FILES_HERE')} <br />
              {t('i18n.newticket.MAX_IMAGES')}
            </Typography>
          </Paper>
          <br />
          {files}
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item lg={6} />
            <Grid item lg={6}>
              <Button
                style={{ float: 'right' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t('i18n.newticket.SEND')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </ThemeProvider>
  );
};

export default NewTicket;
