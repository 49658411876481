import {
  Box,
  CircularProgress,
  Container,
  makeStyles
} from '@material-ui/core';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { defaultFilter } from '../../dashboardV2/TicketsDashboard/config';
import FetchContentError from '../../../components/FetchContentError';
import Page from '../../../components/Page';
import API from '../../../services/api';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import { COLORS } from '../../../styles/settings/colors.config';
import formHelper from '../../../utils/formHelper';
import TicketsFilter from '../TicketsFilter';
import './styles.css';
import {
  CustomDataTableWrapper,
  CustomDataTable
} from '../../../styles/components/CustomDataTable';

import marketPlacesEnum from '../../../enums/marketPlaces.enum';
import RequestDialog from '../TicketContainer/Cancelling/Dialogs/RequestDialog';
import userService from '../../../services/user.service';
import AttribuitionModal from './components/AttribuitionModal';

import { useMarketplaceList } from '../../../hooks/hooks';

import CustomHeader from './components/CustomHeader';
import { getColumns } from './tableData/columns';
import { getOptions } from './tableData/options';
import { filterFormData } from './formData/data';
import { formatedFilterData } from './functions';
import { getParamsInUrl } from '../../../helpers/globalFunctions';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '12px',
    margin: '-50px 0 10px 5px',
    color: COLORS.btnDangerColor
  },
  header: {
    width: '100% !important'
  }
}));

const TicketsCustomDataTable = styled(CustomDataTable)`
  div[class*='MUIDataTableToolbar-root'] > div {
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

const TicketsListView = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const [data, setData] = useState([]);
  const [hasFetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [sortData, setSortData] = useState({
    column: 'vipClient',
    direction: 'none'
  });
  const isSortDataInitialMount = useRef(true);

  const [defaultSortChanged, setDefaultSortChanged] = useState(false);
  const [openReversalDialog, setOpenReversalDialog] = useState(false);
  const [currentCancelProcess, setCurrentCancelProcess] = useState(false);
  const [currentProtocol, setCurrentProtocol] = useState(false);
  const groups = userService.getGroups();

  const [openProtocolAttribuition, setOpenProtocolAttribuition] =
    useState(false);

  const [ticketsListIds, setTicketsListIds] = useState([]);
  const [selectedRowsState, setSelectedRowsState] = useState([]);
  const classes = useStyles();

  const columns = getColumns(
    t,
    data,
    defaultSortChanged,
    classes,
    formHelper,
    groups,
    setCurrentCancelProcess,
    setCurrentProtocol,
    setOpenReversalDialog
  );

  const localFilterData = getParamsInUrl();

  const sessionFilterData = JSON.parse(sessionStorage.getItem('filterData'));
  let storedFilterData = sessionStorage.getItem('filterData');

  if (localFilterData !== null) {
    const localfilter = {};
    localfilter.lastStepName = localFilterData.lastStepName;
    localfilter.marketPlace = localFilterData.marketplace;
    localfilter.ticketStatus =
      localFilterData.status || localFilterData.ticketStatus;
    localfilter.serviceAttendantLogin = localFilterData.attendant;
    localfilter.reclamacaoMercadoLivre = localFilterData.reclamacaoMercadoLivre;
    localfilter.withReason = localFilterData.withReason;
    localfilter.mediatedClosed = localFilterData.mediatedClosed;
    localfilter.mediatedOpened = localFilterData.mediatedOpened;
    localfilter.incidentClosed = localFilterData.incidentClosed;
    localfilter.incidentOpened = localFilterData.incidentOpened;
    localfilter.protocoloMarketplace = localFilterData.protocoloMarketplace;
    localfilter.isRealTime = localFilterData.isRealTime;
    localfilter.reasonGroup = localFilterData.reasonGroup;
    localfilter.reasonName = localFilterData.reasonName;
    localfilter.reasonDesc = localFilterData.reasonDesc;
    localfilter.sla = localFilterData.sla;
    localfilter.magaluClaim = localFilterData.magaluClaim;
    localfilter.protocolSchedulingStatus =
      localFilterData.protocolSchedulingStatus;
    if (localFilterData.dateAliasInAWayHumanCanRead !== 'realTime') {
      localfilter.creationDate = `${moment
        .utc(localFilterData.startDate)
        .format('DD/MM/YYYY HH:mm:00')}-${moment
        .utc(localFilterData.endDate)
        .format('DD/MM/YYYY HH:mm:ss')}`;
    }

    if (
      localFilterData.dateAliasInAWayHumanCanRead === 'realTime' &&
      !localFilterData.protocolSchedulingStatus
    ) {
      localfilter.creationDate = `${moment

        .utc(localFilterData.startDate)

        .format('DD/MM/YYYY HH:mm:00')}-${moment

        .utc(localFilterData.endDate)

        .format('DD/MM/YYYY HH:mm:ss')}`;
    }

    if (localFilterData.protocolSchedulingStatus) {
      delete localfilter.creationDate;
    }

    storedFilterData = JSON.stringify({ ...sessionFilterData, ...localfilter });
  }

  const allMarketplaces = [...Object.values(marketPlacesEnum)];

  const shortcutsFilter = [
    {
      icon: t('i18n.shortcut.ticketStatus.NEW'),
      value: 'NEW',
      text: t('i18n.ticketslistview.ticketStatus.NEW')
    }
  ];

  if (lastLocation?.pathname.search('ticket-container') === -1) {
    sessionStorage.setItem('lastTicketsPage', 0);
    lastLocation.pathname = 'ticket-container';
  }

  const marketplaces = useMarketplaceList();

  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem('lastTicketsPage')) || 0
  );
  const [filterData, setFilterData] = useState(
    storedFilterData
      ? JSON.parse(storedFilterData)
      : {
          marketPlace: marketplaces.map((mktp) => mktp.value)
        }
  );

  const [indicators, setIndicators] = useState({
    expired: 0,
    expiresToday: 0,
    active: 0
  });

  const getIndicators = async () => {
    const result = await API.post(
      '/auth/ms-ticket/v1/tickets/protocol-scheduling-counting',
      formatedFilterData(filterData)
    );

    setIndicators({
      expired: result?.data?.expiredCounting,
      expiresToday: result?.data?.expiresTodayCounting,
      active: result?.data?.activeCounting
    });
  };

  const refreshTableContent = async (page = currentPage) => {
    const sortingParameters =
    sortData.direction !== 'none'
    ? `&sort=${sortData.column},${sortData.direction}`
    : '';
    setCurrentPage(page);
    setLoading(true);
    try {
      const response = await API.post(
        `/auth/ms-ticket/v1/tickets/?page=${page}${sortingParameters}`,
        formatedFilterData(filterData)
      );
      const mData = response.data;
      const content = mData?._embedded;
      setData(content?.ticketResourceList);
      setData((prevData) => (!content ? [] : prevData));
      setPageData(mData?.page);
      setFetchError(false);
      sessionStorage.setItem('lastTicketsPage', page);
    } catch (error) {
      if (error.response.data.key === 'i18n.exception.ORDER_API_UNAVAILABLE') {
        setData([]);
        enqueueSnackbar(t('i18n.exception.ORDER_API_UNAVAILABLE'), {
          variant: 'error'
        });
      } else {
        setFetchError(true);
      }
    }
    setLoading(false);
  };

  const getTicketType = () => {
    return window.location.pathname === '/ticketsPreSale'
      ? 'PRE_SALE'
      : 'AFTER_SALE';
  };

  const setSessionStorageFilterDataTicketType = (
    newTicketType,
    actualFilter = storedFilterData
  ) => {
    let newFilterData = {};
    if (actualFilter) {
      newFilterData = JSON.parse(actualFilter);
      newFilterData.ticketType = newTicketType;
    } else {
      newFilterData = { ticketType: newTicketType };
    }
    sessionStorage.setItem('filterData', JSON.stringify(newFilterData));
  };

  useEffect(() => {
    if (!sessionStorage.getItem('newfilter')) {
      sessionStorage.setItem('newfilter', JSON.stringify(defaultFilter));
    }
    setSessionStorageFilterDataTicketType(getTicketType());
    getIndicators();
  }, []);

  useEffect(() => {
    if (isSortDataInitialMount.current) {
      isSortDataInitialMount.current = false;
    } else if (sortData.direction !== 'none') {
      refreshTableContent(currentPage);
    }
  }, [sortData]);

  useEffect(() => {
    refreshTableContent(currentPage);
    if (!sessionStorage.getItem('filterData')) {
      filterData.marketPlace = marketplaces.map((mktp) => mktp.value);
    }
    filterData.ticketType = getTicketType();
    sessionStorage.setItem('filterData', JSON.stringify(filterData));
  }, [filterData]);

  if (hasFetchError) {
    return <FetchContentError fetch={refreshTableContent} />;
  }

  const handleSearchAcerStore = () => {
    return false;
  };

  const options = getOptions(
    pageData,
    currentPage,
    refreshTableContent,
    setDefaultSortChanged,
    sortData,
    setSortData,
    setCurrentPage,
    data,
    setSelectedRowsState,
    enqueueSnackbar,
    t,
    history,
    setTicketsListIds,
    setOpenProtocolAttribuition,
    ticketsListIds,
    selectedRowsState,
    loading
  );

  const removeFilterFromChip = (key) => {
    if (key === 'whatsapp') {
      filterData.whatsapp = '';
    } else {
      delete filterData[key];
    }

    if (key === 'reasonName') {
      delete filterData.reasonDesc;
      delete filterData.reasonGroup;
    }

    setFilterData({ ...filterData });
  };

  const componentSetMarketplaceFilter = (val) => {
    const currentShortcut = {
      ticketStatus: filterData.ticketStatus,
      ticketType: filterData.ticketType,
      whatsapp: filterData.whatsapp
    };

    if (typeof filterData.marketPlace === 'string') {
      filterData.marketPlace = filterData.marketPlace.split(',');
    }

    let currentMarketplaceFilters = filterData.marketPlace;
    let shortcutFilter = {};
    if (currentMarketplaceFilters.includes(val)) {
      if (val === 'All') {
        currentMarketplaceFilters = [];
      } else if (currentMarketplaceFilters.includes('All')) {
        currentMarketplaceFilters.splice(
          currentMarketplaceFilters.indexOf(val),
          1
        );
        currentMarketplaceFilters.splice(
          currentMarketplaceFilters.indexOf('All'),
          1
        );
      } else {
        currentMarketplaceFilters.splice(
          currentMarketplaceFilters.indexOf(val),
          1
        );
      }
    } else if (val === 'All') {
      currentMarketplaceFilters = allMarketplaces;
      currentMarketplaceFilters.push(val);
    } else if (val === 'NEW') {
      shortcutFilter = {
        ...shortcutFilter,
        ticketStatus: currentShortcut.ticketStatus?.includes(val) ? null : val
      };
    } else if (val === 'PRE_SALE' || val === 'AFTER_SALE') {
      shortcutFilter = {
        ...shortcutFilter,
        ticketType: currentShortcut.ticketType?.includes(val) ? null : val
      };
    } else if (val === 'WHATSAPP') {
      shortcutFilter = {
        ...shortcutFilter,
        whatsapp: !currentShortcut.whatsapp
      };
    } else {
      currentMarketplaceFilters.push(val);
      if (currentMarketplaceFilters.length === allMarketplaces.length) {
        currentMarketplaceFilters.push('All');
      }
    }

    const newFilterData = {
      ...filterData,
      ...shortcutFilter,
      marketPlace: currentMarketplaceFilters
    };

    sessionStorage.setItem('lastTicketsPage', 0);
    setCurrentPage(0);
    setFilterData(newFilterData);
    sessionStorage.setItem('useFilterInParams', false); // Ignore filter by query
  };

  return (
    <Page title={t('i18n.ticketslistview.TICKETS')}>
      <TicketsFilter
        selectedOptions={filterData.marketPlace}
        shortcutSelected={{
          ticketStatus: filterData.ticketStatus,
          ticketType: filterData.ticketType,
          whatsapp: filterData.whatsapp
        }}
        shortcutsFilter={shortcutsFilter}
        setMarketplaceFunction={componentSetMarketplaceFilter}
        setCurrentPage={setCurrentPage}
        filterData={filterData}
        setFilterData={setFilterData}
        filterFormData={filterFormData}
        removeFilterFromChip={removeFilterFromChip}
      />

      <Container maxWidth={false}>
        <Box mt={3} ml={7}>
          <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
            <TicketsCustomDataTable
              title={
                <CustomHeader
                  {...{
                    refreshTableContent,
                    handleSearchAcerStore,
                    currentPage,
                    filterData,
                    removeFilterFromChip,
                    filterFormData,
                    indicators
                  }}
                />
              }
              data={data}
              columns={columns}
              options={options}
              selectableRowsOnClick={!!userService.hasRole('ticket_detail')}
              className="table-clickable"
            />
          </CustomDataTableWrapper>
          <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </Box>
      </Container>
      <RequestDialog
        open={openReversalDialog}
        type="finance"
        data={currentProtocol}
        setOpen={setOpenReversalDialog}
        currentCancelProcess={currentCancelProcess}
        title="Registrar estorno"
      />

      <AttribuitionModal
        ticketsListIds={ticketsListIds}
        setTicketsListIds={setTicketsListIds}
        openProtocolAttribuition={openProtocolAttribuition}
        setOpenProtocolAttribuition={setOpenProtocolAttribuition}
        refreshTableContent={refreshTableContent}
        currentPage={currentPage}
      />
    </Page>
  );
};

export default TicketsListView;
